input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.detail-table-style {
  border-collapse: collapse;
  /* border-style: hidden; */
  border-spacing: 0px;
  border-radius: 7px;
  background: white;
  /* border: 1px ridge #78909c; */
  /* text-overflow: clip; */
  border-spacing: 0px;
}

.sectionBuilderStyle {
  margin-top: 35px;
  /* border: 1px dashed #212121; */
  /* background: linear-gradient(to bottom, #495257, #80888d); */
  background: #418991;
  /* font-family: Garamond; */
  font-family: Arial, sans-serif;
  font-weight: bold;
  color: #FFFAFA;
  font-size: 16px;
}

.bodyBuilderStyle {
  /* padding-top: 4px; */
  /* font-family: Garamond;   */
  padding-left: 10px;
  border-bottom: 1px solid grey;
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #212121;
  text-align: left;
  /* text-shadow:#FFFAFA 1px 1px; */
}

.pagination-rows-amount-style {
  color: black;
  font-size: 16px;
  padding-right: 5px;
}

/* DRAGGABLE DIVS */
.main-table-label-div-style {
  text-align-last: left;
  margin-left: 30px;
  border-bottom: 1px solid grey;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #eff8fd;
  font-weight: bold;
  margin-bottom: 0;
}

.main-table-label-p-style {
  width: 100%;
  cursor: move;
  text-align-last: center;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;
  background-color: #eff8fd;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main-table-style {
  border-collapse: collapse;
  border-style: hidden;
  border-spacing: 0px;
  border-radius: 7px;
  background: white;
  /* text-overflow: clip; */
  border-spacing: 0px;
}

.main-table-thead-style {
  z-index: 30;
}

.main-table-style-small {
  position: relative;
  border-collapse: collapse;
  border-style: hidden;
  border-spacing: 0px;
  border-radius: 7px;
  /* text-overflow: clip; */
}

.main-table-header-td-style {
  position: sticky;
  top: 0;
  background: #ced3d4;
  resize: horizontal;
  overflow: hidden;
  color: black;
  font-size: 11px;
  border: 1px solid #2e2e2e;
  text-align: left;
  z-index: 99999;

  /* width: 30px;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.main-table-tbody-td-style {
  font-size: 12;
  font-size: 11px;
  border-bottom: thin solid grey;
  cursor: pointer;
  overflow: hidden;
  /* white-space: nowrap; 
    text-overflow: ellipsis; */
}

.dragble-div-header-table-style {
  border: 1px solid #78909c;
  margin-top: 1;
  margin-bottom: 2;
  text-align: center;
  font-weight: bold;
}

.dragble-div-header-td-style {
  text-align: center;
  line-height: 2px;
  font-size: 15px;
  /* white-space: nowrap; 
    text-overflow: ellipsis; */
}

.dragble-div-body-style {
  /* padding-left: 10px; */
  border: 1px ridge #78909c;
  background-color: #fcfcfc;
  /* margin-top: 1px; */
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.dragble-div-body-td-text-style {
  font-size: 15px;
  /* font-family: "Courier New"; */
  font-weight: bold;
}

.dragble-div-body-td-simple-text-style {
  font-size: 12px;
  /* font-family: "Courier New"; */
  /* font-weight: bold; */
}

.add-icon-style {
  position: absolute;
  margin-left: 5px;
  margin-top: 7px;
  cursor: pointer;
}

.reduce-icon-style {
  position: absolute;
  margin-left: 35px;
  margin-top: 7px;
  cursor: pointer;
}

/* TABLE */

/* TABLE New design */
/* main Grid tables (instruments, bids, deals, etc.) */
th::-webkit-resizer {
  background: transparent;
}

td::-webkit-resizer {
  background: transparent;
}

.main-table-label-div-style-new {
  text-align-last: left;
  /* padding-bottom: 10px; */
  border-bottom: 1px solid #a7a5a5;
  /* border-top-left-radius: 7px;
  border-top-right-radius: 7px; */
  background-color: white;
  /* font-weight: bold; */
  margin-bottom: 0;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* font-family: 'Roboto'; */
}

.main-table-label-p-style-new {
  width: 100%;
  cursor: move;
  text-align-last: center;
  margin-bottom: 0;
  margin-top: 0;
  background-color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 18px;
  height: 40px;
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: Helvetica; */
}

/* .main-table-label{
  font-family: 'Roboto', sans-serif;
} */

.main-table-style-new {
  /* border-collapse: collapse; */
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  background: white;
  /* font-size: 6; */
  /* font-family: 'Open Sans'; */
  /* font-style: italic; */
  /* display: swap; */
}

.main-table-header-th-style-new {
  position: sticky;
  top: 0;
  /* background: #F6F5F5; */
  padding: 2px 4px 2px 8px;
  height: 28px;
  resize: horizontal;
  overflow: hidden;
  /* font-size: 14px; */
  font:  normal 14px arial;
  /* font-weight: normal; */
  color: #000;
  background: #F6F4F3;
  /* text-align: left; */
  box-shadow: inset -1px -1px 1px 0 rgba(0,0,0,.2), inset 2px 2px 2px 0 rgba(255,255,255,0.5);
  /* font-weight: 'bold'; */
  /* font-family: 'Open Sans'; */
  border: 1px solid #eee;
  z-index: 99999;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}
.main-table-number-style {
  position: sticky;
  left: 0;
  padding: 2px 4px 2px 4px;
  color: #000;
  /* height: 28px; */
  background: #F6F4F3;
  resize: horizontal;
  overflow: hidden;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  background: #F6F4F3;
  text-align: center;
  box-shadow: inset -1px -1px 1px 0 rgba(0,0,0,.2), inset 2px 2px 2px 0 rgba(255,255,255,0.5);
  border: 1px solid #eee;
  z-index: 99999;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.main-table-tbody-td-style-new {
  font-size: 11px;
  /* border-bottom: thin solid #D5D5D5; */
  cursor: pointer;
  overflow: hidden;
  /* font-family: Helvetica; */
  /* width: 20px; */
  /* min-width: 10px; */
  width: min-content;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Dragable component style */
.dragble-div-header-table-style-new {
  /* border: 1px solid #78909c; */
  /* margin-top: 1; */
  margin-bottom: 2;
  text-align: center;
  font-weight: bold;
}

.dragble-div-header-td-style-new {
  text-align: center;
  /* line-height: 2px; */
  font-size: 15px;
  /* white-space: nowrap; 
  text-overflow: ellipsis; */
}

.dragble-div-body-style-new {
  /* padding-left: 10px; */
  border-top: 1px ridge #78909c;
  /* background-color: #fcfcfc; */
  /* margin-top: 1px; */
  height: 100%;
  width: 100%;
  overflow: scroll;
}

/* Grid tables in Dragable component forms */
.grid-table-style {
  border-collapse: collapse;
  border-style: hidden;
  border-spacing: 0px;
  border-radius: 3px;
  background: white;
  border-spacing: 0px;
  width: 100%;
}

.grid-table-thead-style {
  background-color: #F6F5F5;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  /* border-right: thin solid grey; */
  font-size: 14px;
  text-align: center;
  height: 24px;
}

/* .grid-table-td-head-first-child{
  border-top-left-radius: 3px;
  color: black;
  font-size: 13px;
  border-right: thin solid #a7a5a5;
  text-align: center;
} */
.grid-table-td-head-first-child {
  border-top-left-radius: 3px;
  color: #494949;
  font-size: 13px;
  border: thin solid #a7a5a5;
}

.grid-table-td-head-last-child {
  border-top-right-radius: 3px;
  color: #494949;
  font-size: 13px;
  border: thin solid #a7a5a5;
}

.grid-table-td-head-style-2row {
  color: #494949;
  font-size: 12px;
  border-right: thin solid #a7a5a5;
  text-align: left;
}

.grid-table-td-style {
  /* width: 100%; */
  color: black;
  font-size: 12px;
  border-bottom: thin solid grey;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.approveButton {
  border: 1px solid #337E86;
  border-radius: 0%;
  color: white;
  background: #337E86;
  height: 30px;
  width: 190px;
  font-family: Helvetica;
  /* font-weight: bold; */
  cursor: pointer;
  white-space: nowrap;
  /* text-overflow: ellipsis; */
}

.cancelButton {
  border: 1px solid #337E86;
  border-radius: 0%;
  color: #337E86;
  /* background: #337E86; */
  height: 30px;
  width: 190px;
  font-family: Helvetica;
  /* font-weight: bold; */
  cursor: pointer;
}

.approveSmallButton {
  border: 1px solid #337E86;
  border-radius: 0%;
  color: white;
  background: #337E86;
  height: 20px;
  width: 70px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormButton {
  /* border: 1px solid #337E86; */
  border-radius: 0%;
  color: white;
  /* background: #337E86; */
  height: 26px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormSearchButton {
  border: 2px solid rgb(108, 179, 192);
  border-radius: 20%;
  color: rgb(4, 141, 165);
  background: #f0feff;
  height: 26px;
  width: 32px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormCreateButton {
  border: 2px solid rgb(124, 219, 140);
  border-radius: 20%;
  color: rgb(40, 192, 2);
  background: #e7ffe7;
  height: 26px;
  width: 32px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormUpdateButton {
  border: 2px solid rgb(140, 84, 245);
  border-radius: 20%;
  color: rgb(62, 8, 163);
  background: #f2e7ff;
  height: 26px;
  width: 32px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormBackButton {
  border: 2px solid rgb(233, 149, 153);
  border-radius: 20%;
  color: rgb(226, 44, 31);
  background: #fff0f0;
  height: 26px;
  width: 32px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.mainFormDeleteButton {
  border: 2px solid rgb(221, 101, 107);
  border-radius: 20%;
  color: rgb(190, 38, 27);
  background: #f8efef;
  height: 26px;
  width: 32px;
  font-family: Helvetica;
  cursor: pointer;
  margin-left: 5px;
}

.gridFormEditButton {
  /* color: #337E86; */
  cursor: pointer;
  padding: 0;
}





.form-expand-icons-style {
  cursor: pointer;
  color: #418991;
  border-radius: 25%;
  margin-top: 5px;
}

.form-close-icons-style {
  cursor: pointer;
  color: #418991;
  border-radius: 25%;
  margin-top: 5px;
  background: #D5E6E8;
}

.form-settings-icons-style {
  cursor: pointer;
  color: #418991;
  border-radius: 25%;
  margin-top: 5px;
  /* background: #D5E6E8; */
}

.bottom-nav-text-style {
  /* white-space: "nowrap";
  text-overflow: "elipsis"; */
  vertical-align: "middle";
  height: 25px;
  color: #8D8D8D;
  cursor: pointer;
}


.thead-style {
  background-color: #cfd8dc;
  font-weight: bold;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.td-head-style {
  background-color: #cfd8dc;
  color: black;
  font-size: 14px;
  border: 1px solid grey;
  text-align: center;
}

.td-head-style-2row {
  color: black;
  font-size: 12px;
  border: thin solid grey;
  text-align: center;
}

.td-style {
  color: black;
  font-size: 12px;
  border-bottom: thin solid grey;
  text-align: center;
}

.td-head-first-child {
  border-top-left-radius: 8px;
  color: black;
  font-size: 13px;
  border: thin solid grey;
  text-align: center;
}

.td-head-last-child {
  border-top-right-radius: 8px;
  color: black;
  font-size: 13px;
  border: thin solid grey;
  text-align: center;
}

.body-style {
  font-size: 12;
}

.td-body-style {
  align-items: left;
  color: black;
  font-size: 12px;
  border-bottom: 1px solid grey
}

.properties-td-text-style {
  font-size: 15px;
  /* font-family: 'Chilanka'; */
}

/* Resizable table */
.resize-table {
  width: 100%;
  border-collapse: collapse;
  border-style: hidden;
  display: grid;
  grid-template-columns:
    minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
}

.resize-table-thead,
.resize-table-tbody,
.resize-table-tr {
  display: contents;
  background-color: #dbf1fa;
}

.resize-table-th {
  position: relative;
  font-size: 12px;
  background-color: #cfd8dc;
  border: 1px solid grey;
}

.resize-table-td {
  text-align: left;
  min-width: 10px;
  color: black;
  font-size: 12px;
  border-bottom: 1px solid grey;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.resize-table-th-span,
.resize-table-td-span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}

.resize-table-tr-td {
  border-top: 1px solid grey;
}

.resize-handle {
  display: block;
  position: absolute;
  cursor: col-resize;
  width: 7px;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
}

.resize-handle:hover {
  border-color: #ccc;
}

.resize-handle.active {
  border-color: #517ea5;
}



.react-checkbox-tree {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  font-size: 16px;
}

.react-checkbox-tree .rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-family: "Font Awesome 5 Free", FontAwesome, sans-serif;
  font-weight: 400;
  font-variant: normal;
  font-style: normal;
}

.react-checkbox-tree>ol {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.react-checkbox-tree ol {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
}

.react-checkbox-tree ol ol {
  padding-left: 24px;
}

.react-checkbox-tree button {
  line-height: normal;
  color: inherit;
}

.react-checkbox-tree button:disabled {
  cursor: not-allowed;
}

.react-checkbox-tree .rct-bare-label {
  cursor: default;
}

.react-checkbox-tree label {
  margin-bottom: 0;
  cursor: pointer;
}

.react-checkbox-tree label:hover {
  background: rgba(51, 51, 204, 0.1);
}

.react-checkbox-tree label:active,
.react-checkbox-tree label:focus {
  background: rgba(51, 51, 204, 0.15);
}

.react-checkbox-tree:not(.rct-native-display) input {
  display: none;
}

.react-checkbox-tree.rct-native-display input {
  margin: 0 5px;
}

.react-checkbox-tree .rct-icon {
  display: inline-block;
  text-align: center;
  text-rendering: auto;
  font-family: "Font Awesome 5 Free", "FontAwesome", sans-serif;
  font-weight: normal;
  font-variant: normal;
  font-style: normal;
}

.rct-disabled>.rct-text>label {
  opacity: 0.75;
  cursor: not-allowed;
}

.rct-disabled>.rct-text>label:hover {
  background: transparent;
}

.rct-disabled>.rct-text>label:active {
  background: transparent;
}

.rct-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rct-options {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 0.5rem;
  text-align: right;
}

.rct-option {
  opacity: 0.75;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 0 4px;
  font-size: 18px;
}

.rct-option:hover {
  opacity: 1;
}

.rct-option+.rct-option {
  margin-left: 2px;
}

.rct-collapse,
.rct-checkbox,
.rct-node-icon {
  padding: 0 5px;
}

.rct-collapse *,
.rct-checkbox *,
.rct-node-icon * {
  display: inline-block;
  margin: 0;
  width: 14px;
}

.rct-collapse {
  -ms-flex-item-align: stretch;
  align-self: stretch;
  border: 0;
  background: none;
  line-height: normal;
  color: inherit;
  font-size: 12px;
}

.rct-collapse.rct-collapse-btn {
  cursor: pointer;
}

.rct-collapse>.rct-icon-expand-close {
  opacity: 0.5;
}

.rct-collapse>.rct-icon-expand-close:hover {
  opacity: 1;
}

.rct-native-display .rct-checkbox {
  display: none;
}

.rct-node-clickable {
  cursor: pointer;
}

.rct-node-clickable:hover {
  background: rgba(51, 51, 204, 0.1);
}

.rct-node-clickable:focus {
  outline: 0;
  background: rgba(51, 51, 204, 0.2);
}

.rct-node-icon {
  color: #33c;
}

.rct-title {
  padding: 0 5px;
}

.rct-icons-fa4 .rct-icon-expand-close::before {
  content: "\f054";
}

.rct-icons-fa4 .rct-icon-expand-open::before {
  content: "\f078";
}

.rct-icons-fa4 .rct-icon-uncheck::before {
  content: "\f096";
}

.rct-icons-fa4 .rct-icon-check::before {
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-half-check::before {
  opacity: 0.5;
  content: "\f046";
}

.rct-icons-fa4 .rct-icon-leaf::before {
  content: "\f016";
}

.rct-icons-fa4 .rct-icon-parent-open::before {
  content: "\f115";
}

.rct-icons-fa4 .rct-icon-parent-close::before {
  content: "\f114";
}

.rct-icons-fa4 .rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icons-fa4 .rct-icon-collapse-all::before {
  content: "\f146";
}

.rct-icons-fa5 .rct-icon-expand-close::before {
  font-weight: 900;
  content: "\f054";
}

.rct-icons-fa5 .rct-icon-expand-open::before {
  font-weight: 900;
  content: "\f078";
}

.rct-icons-fa5 .rct-icon-uncheck::before {
  content: "\f0c8";
}

.rct-icons-fa5 .rct-icon-check::before {
  content: "\f14a";
}

.rct-icons-fa5 .rct-icon-half-check::before {
  opacity: 0.5;
  content: "\f14a";
}

.rct-icons-fa5 .rct-icon-leaf::before {
  content: "\f15b";
}

.rct-icons-fa5 .rct-icon-parent-open::before {
  content: "\f07c";
}

.rct-icons-fa5 .rct-icon-parent-close::before {
  content: "\f07b";
}

.rct-icons-fa5 .rct-icon-expand-all::before {
  content: "\f0fe";
}

.rct-icons-fa5 .rct-icon-collapse-all::before {
  content: "\f146";
}

.rct-direction-rtl {
  direction: rtl;
}

.rct-direction-rtl ol ol {
  padding-right: 24px;
  padding-left: 0;
}

.rct-direction-rtl.rct-icons-fa4 .rct-icon-expand-close::before {
  content: "\f105";
}

.rct-direction-rtl.rct-icons-fa5 .rct-icon-expand-close::before {
  content: "\f053";
}


.resizable {
  position: absolute;
  height: 400px;
  border: 1px solid red;
}

.resizer {
  /* All resizers are positioned absolutely inside the element */
  position: absolute;
}

/* Placed at the right side */
.resizer-r {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  width: 5px;
}

/* Placed at the bottom side */
.resizer-b {
  bottom: 0;
  cursor: row-resize;
  height: 5px;
  left: 0;
  width: 100%;
}